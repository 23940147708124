<template>

  <div class="pb" v-if="edit_data">

     <div v-if="edit_data && edit_data.seed">
       <n-message-provider>
         <EditSeed
          :edit_data="edit_data.seed"
          />
        </n-message-provider>
     </div>

     <div v-if="edit_data && edit_data.nutrient">
       <n-message-provider>
         <EditNutrient
          :edit_data="edit_data.nutrient"
          />
        </n-message-provider>
     </div>

     <div v-if="edit_data && edit_data.lamp">
       <n-message-provider>
         <EditLamp
          :edit_data="edit_data.lamp"
          />
        </n-message-provider>
     </div>

     <div v-if="edit_data && edit_data.tent">
       <n-message-provider>
         <EditTent
          :edit_data="edit_data.tent"
          />
        </n-message-provider>
     </div>

   </div>
     
</template>

<script setup>

import { NMessageProvider } from 'naive-ui'

import EditSeed from '@/pages/brands-manager/components/EditSeed'
import EditNutrient from '@/pages/brands-manager/components/EditNutrient'
import EditLamp from '@/pages/brands-manager/components/EditLamp'
import EditTent from '@/pages/brands-manager/components/EditTent'

const {$api} = useNuxtApp()
const route = useRoute();

const loadData = async function() { 
  const response = await $api.getB2BProduct(route.params['id'],route.query['tmp_id']);
  return response; 
}

const { pending: is_loading,  data: edit_data } = await useLazyAsyncData('edit_data', async () => await loadData())

useHead(useNuxtApp().$head.getBrandManager());

onUnmounted(() => {
  edit_data.value = null;
})

</script>

<style scoped>

</style>

<style>
 
.label {
  display: block;
  margin: 0em 0em 0.28571429rem 0em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
}

</style>